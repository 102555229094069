export const MOST_SEARCHED_PRODUCTS = `
  query MostSearchedProducts {
    public {
      mostSearchedProductsV1 {
        id
        title
        categoryId
        image {
          id
          url(width: 144)
        }
        indexSource
        currentMinPrice
        totalStoresCount
        minPriceStore {
          name
        }
        rangePercentageMessage {
          status
        }
      }
    }
  }
`;
