import { Subject, debounceTime, switchMap } from 'rxjs';
import { searchSuggestions } from '../endpoints';
import { FederatedSearchContext } from '../types/FederatedSearch';

export const DEFAULT_TERM_SUGGESTION_LIMIT = 4;
export const DEFAULT_PRODUCT_SUGGESTIONS_LIMIT = 4;

export const suggestionsRequest = (query: string) =>
  searchSuggestions.request({
    input: [
      {
        context: FederatedSearchContext.Suggestion,
        limit: DEFAULT_TERM_SUGGESTION_LIMIT,
        query,
      },
      {
        context: FederatedSearchContext.Product,
        limit: DEFAULT_PRODUCT_SUGGESTIONS_LIMIT,
        query,
      },
    ],
  });

export const makeSearchSuggestionsRequester = () => {
  const emitter = new Subject<string>();

  const debouncedRequestAndCache$ = emitter.pipe(
    debounceTime(400),
    switchMap(suggestionsRequest),
    switchMap(() => searchSuggestions.cache$)
  );

  return {
    getSearchSuggestions: (query: string) => emitter.next(query),
    debouncedRequestAndCache$,
  };
};

export const clearSuggestions = () => {
  searchSuggestions.cache$.next({
    products: [],
    suggestions: [],
    loading: false,
  });
};
