export const GET_SEARCH_TERMS_SUGGESTIONS = `
query GetSearchTermsSuggestions($text: String!, $limit: Int) {
  public {
    searchTermsSuggestions(text: $text, limit: $limit) {
      suggestions {
        keyword
        faq {
          data {
            question
            answer
          }
        }
      }
      shouldIndexSearch
    }
  }
}
`;
