export const GET_BLACK_FRIDAY_TERMS_SUGGESTIONS = `
query GetSearchTermsSuggestions($text: String!, $limit: Int) {
  public {
    searchTermsSuggestions(text: $text, limit: $limit) {
      suggestions {
        keyword
        pathUrl
      }
    }
  }
}
`;
