import { useEffect } from 'react';
import { getMostFrequentSearches } from '@/infra/api/search/endpoints';
import { useApiCache } from '@/presentation/hooks/useApiCache';

export const useFrequentSearches = () => {
  const { frequentSearches = [] } =
    useApiCache(getMostFrequentSearches.cache$) || {};

  const hasFrequentSearches = frequentSearches.length > 0;

  useEffect(() => {
    if (hasFrequentSearches) return;

    getMostFrequentSearches.requestAsPromise();
  }, [hasFrequentSearches]);

  return {
    frequentSearches,
  };
};
