export const RANGE_FACET_OPTION_FRAGMENT = `
  fragment RangeFacetOptionFragment on RangeFacetOption {
    from
    to
  }
`;

export const TEXT_FACET_OPTION_FRAGMENT = `
  fragment TextFacetOptionFragment on TextFacetOption {
    values {
      count
      value
    }
  }
`;

export const FACETS_FRAGMENT = `
  fragment FacetsFragment on Facet {
    type
    field
    name
    options {
      ...RangeFacetOptionFragment
      ...TextFacetOptionFragment
    }
  }

  ${TEXT_FACET_OPTION_FRAGMENT}
  ${RANGE_FACET_OPTION_FRAGMENT}
`;
