const prefix = '@search';

export const SearchActionType = {
  GetSearchOffer: `${prefix}/GET_SEARCH_OFFER`,
  GetMostFrequentSearch: `${prefix}/GET_MOST_FRAQUENT_SEARCH`,
  SearchStores: `${prefix}/SEARCH_STORES`,
  SearchProducts: `${prefix}/SEARCH_PRODUCTS`,
  SearchDeals: `${prefix}/SEARCH_DEALS`,

  FederatedSearch: `${prefix}/FEDERATED_SEARCH`,
  SearchSuggestions: `${prefix}/SEARCH_SUGGESTIONS`,
  MostSearchedProducts: `${prefix}/MOST_SEARCHED_PRODUCTS`,
  GetSearchTermsSuggestions: `${prefix}/GET_SEARCH_TERMS_SUGGESTIONS`,
  GetBlackFridayTermsSuggestions: `${prefix}/GET_BLACK_FRIDAY_TERMS_SUGGESTIONS`,
};
