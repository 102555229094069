export enum SearchSuggestionType {
  RECENTS = 'recents',
  MOST_SEARCHED = 'most_searched',
  STORES = 'stores',
  PRODUCTS = 'products',
  AUTOCOMPLETE = 'autocomplete',
}

export type RecentsSearchSuggestionPayload = {
  word: string;
  type: SearchSuggestionType.RECENTS;
};

export type MostSearchedSuggestionPayload = {
  word: string;
  type: SearchSuggestionType.MOST_SEARCHED;
};

export type ProductSuggestionPayload = {
  id: string;
  type: SearchSuggestionType.PRODUCTS;
};

export type AutocompleteSuggestionPayload = {
  suggestion: string;
  url: string;
  type: SearchSuggestionType.AUTOCOMPLETE;
};

export type SearchSuggestionPayload =
  | RecentsSearchSuggestionPayload
  | MostSearchedSuggestionPayload
  | ProductSuggestionPayload
  | AutocompleteSuggestionPayload;

export type ProductSuggestion = {
  id: string;
  name: string;
  url: string;
  image: string;
};

export type AutoCompleteSuggestion = {
  suggestion: string;
  url: string;
};
