import { createNewEndpoint } from '../core/endpoint';
import { GraphQlMethods } from '../core/fetch';
import { SearchActionType } from './actionTypes';
import { parseFederatedResultToCache } from './utils/federatedSearch/cache';
import { FEDERATED_SEARCH_QUERY } from './queries/federatedSearch';
import {
  FederatedSearchCache,
  FederatedSearchContext,
  FederatedSearchResponse,
  FederatedSearchVariables,
  TermSuggestion,
} from './types/FederatedSearch';
import { SearchSuggestionsCache } from './types/SearchSuggestion';
import { Product } from '../../../domain/entities/Product';
import {
  MostSearchedProductsResponse,
  MostSearchedProductsVariables,
} from './types/MostSearchedProducts';
import { MOST_SEARCHED_PRODUCTS } from './queries/MostSearchedProducts';
import {
  MostFrequentSearchesCache,
  MostFrequentSearchesResponse,
  MostFrequentSearchesVariables,
} from './types/GetMostRequentSearches';
import MOST_FREQUENT_SEARCHES_QUERY from './queries/mostFrenquetSearchesQuery';
import {
  GetSearchTermsSuggestionsResponse,
  GetSearchTermsSuggestionsVariables,
} from './types/GetSearchTermsSuggestions';
import { GET_SEARCH_TERMS_SUGGESTIONS } from './queries/searchTermsSuggestions';
import {
  type GetBlackFridayTermsSuggestionsResponse,
  type GetBlackFridayTermsSuggestionsVariables,
} from './types/GetBlackFridayTermsSuggestions';
import { GET_BLACK_FRIDAY_TERMS_SUGGESTIONS } from './queries/blackFridayTermsSuggestions';

export const federatedSearch = createNewEndpoint<
  FederatedSearchResponse,
  FederatedSearchVariables,
  FederatedSearchResponse['public']['federatedSearchV1'],
  FederatedSearchCache
>({
  id: SearchActionType.FederatedSearch,
  query: FEDERATED_SEARCH_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.federatedSearchV1,
  cacheTransform: parseFederatedResultToCache,
});

export const searchSuggestions = createNewEndpoint<
  FederatedSearchResponse,
  FederatedSearchVariables,
  FederatedSearchResponse['public']['federatedSearchV1']['searches'],
  SearchSuggestionsCache
>({
  id: SearchActionType.SearchSuggestions,
  query: FEDERATED_SEARCH_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.federatedSearchV1.searches,
  cacheTransform: ({ data, loading }, current) => {
    if (loading) {
      return {
        products: current?.products || [],
        suggestions: current?.suggestions || [],
        loading,
      };
    }

    const products = (data?.find(
      (search) => search.context === FederatedSearchContext.Product
    )?.edges || []) as Product[];

    const suggestions = (data?.find(
      (search) => search.context === FederatedSearchContext.Suggestion
    )?.edges || []) as TermSuggestion[];

    return {
      products,
      suggestions,
      loading,
    };
  },
});

export const mostSearchedProducts = createNewEndpoint<
  MostSearchedProductsResponse,
  MostSearchedProductsVariables,
  Product[]
>({
  id: SearchActionType.MostSearchedProducts,
  query: MOST_SEARCHED_PRODUCTS,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.mostSearchedProductsV1,
});

export const getMostFrequentSearches = createNewEndpoint<
  MostFrequentSearchesResponse,
  MostFrequentSearchesVariables,
  MostFrequentSearchesResponse['public']['mostFrequentSearches'],
  MostFrequentSearchesCache
>({
  id: SearchActionType.GetMostFrequentSearch,
  query: MOST_FREQUENT_SEARCHES_QUERY,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.mostFrequentSearches,
  cacheTransform: ({ data }, current) => ({
    frequentSearches: data || current?.frequentSearches || [],
  }),
});

export const getSearchTermsSuggestions = createNewEndpoint<
  GetSearchTermsSuggestionsResponse,
  GetSearchTermsSuggestionsVariables,
  GetSearchTermsSuggestionsResponse['public']['searchTermsSuggestions']
>({
  id: SearchActionType.GetSearchTermsSuggestions,
  query: GET_SEARCH_TERMS_SUGGESTIONS,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.searchTermsSuggestions,
});

export const getBlackFridayTermsSuggestions = createNewEndpoint<
  GetBlackFridayTermsSuggestionsResponse,
  GetBlackFridayTermsSuggestionsVariables,
  GetBlackFridayTermsSuggestionsResponse['public']['searchTermsSuggestions']
>({
  id: SearchActionType.GetBlackFridayTermsSuggestions,
  query: GET_BLACK_FRIDAY_TERMS_SUGGESTIONS,
  method: GraphQlMethods.Get,
  transform: (response) => response.public.searchTermsSuggestions,
});
