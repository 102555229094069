import { useCallback, useMemo } from 'react';
import { SearchType } from '@/presentation/pages/public/Search/types';
import { getSearchUrl } from '@/presentation/services/urls/search';
import { makeSearchSuggestionsRequester } from '../../../../infra/api/search/helpers/searchSuggestions';
import { useApiCache } from '../../../hooks/useApiCache';
import { getProductUrl } from '../../../services/urls';
import {
  AutoCompleteSuggestion,
  ProductSuggestion,
} from '../components/HeaderSearchBar/types';
import { Product } from '../../../../domain/entities/Product';
import { TermSuggestion } from '../../../../infra/api/search/types/FederatedSearch';

const mapProductsToProductSuggestion = (
  products: Product[]
): ProductSuggestion[] =>
  products.map(({ id, image, title }) => ({
    id,
    image: image.url,
    name: title,
    url: getProductUrl(id, title),
  }));

const mapSuggestionToAutocompleteSuggestion = (
  suggestions: TermSuggestion[],
  searchType?: SearchType
): AutoCompleteSuggestion[] =>
  suggestions.map(({ suggestion }) => ({
    suggestion,
    url: getSearchUrl({ keyword: suggestion, searchType }),
  }));

const { debouncedRequestAndCache$, getSearchSuggestions } =
  makeSearchSuggestionsRequester();

export function useAutocompleteSuggestion(searchType?: SearchType) {
  const {
    suggestions: cacheSuggestions,
    products: cacheProducts,
    loading,
  } = useApiCache(debouncedRequestAndCache$) || { loading: true };

  const suggestions: AutoCompleteSuggestion[] = useMemo(
    () =>
      mapSuggestionToAutocompleteSuggestion(cacheSuggestions || [], searchType),
    [cacheSuggestions, searchType]
  );

  const products = useMemo(
    () => mapProductsToProductSuggestion(cacheProducts || []),
    [cacheProducts]
  );

  const getSuggestions = useCallback(getSearchSuggestions, []);

  return { suggestions, products, getSuggestions, loading };
}
